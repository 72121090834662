// TO DO: Fix types for Drip Campaigns and improve the ICampaignGql interface

import { ApolloClient, ApolloQueryResult } from '@apollo/client'
import type { ApolloContext } from '~/services/types/Gql'
import type { ISingleDripCampaignType, IDripCampaignCreate, DripCampaignSubscription } from '~/services/types/Campaign'
import type { DripCampaignUpdate, DripCampaignMessageType, DripCampaignMessageCreate, DripCampaignSubscriptionListType } from '~/types/generated'
import updateDripCampaign from '@/graphql/drip-campaigns/updateDripCampaign.gql'
import createDripCampaign from '@/graphql/drip-campaigns/createDripCampaign.gql'
import getDripCampaignById from '@/graphql/drip-campaigns/getDripCampaignById.gql'
import getDripCampaigns from '@/graphql/campaigns/getCampaigns.gql'
import getDripCampaignSubscriptions from '@/graphql/drip-campaigns/suscriptions/getDripCampaignSubscriptions.gql'
import dripCampaignMessageCreate from '@/graphql/drip-campaigns/messages/dripCampaignMessageCreate.gql'
import dripCampaignMessageUpdate from '@/graphql/drip-campaigns/messages/dripCampaignMessageUpdate.gql'
import dripCampaignMessageDelete from '@/graphql/drip-campaigns/messages/dripCampaignMessageDelete.gql'
import dripCampaignSuscriptionCreate from '@/graphql/drip-campaigns/suscriptions/dripCampaignSuscriptionCreate.gql'
import dripCampaignSuscriptionUpdate from '@/graphql/drip-campaigns/suscriptions/dripCampaignSuscriptionUpdate.gql'
import dripCampaignSuscriptionDelete from '@/graphql/drip-campaigns/suscriptions/dripCampaignSuscriptionDelete.gql'
import useSnackbar from '~/composables/useSnackbar'

type RouteIds = {
    campaignId: string,
    phoneNumberId: string,
    tenantId: string
}
type PageParams = {
    page: number,
    itemsPerPage: number
}
interface ICampaignGql {
  $apollo: ApolloClient<any>
  create: (
    activeDate: string | null,
    automaticResponse: string,
    data: null,
    name: string,
    phoneNumberId: string,
    tenantId: string,
    unsubscribeOnReply: boolean
  ) => Promise<IDripCampaignCreate>
  update: (
    routeIds: RouteIds,
    campaign: IDripCampaignCreate
  ) => Promise<DripCampaignUpdate>
  getById: (
    id: string,
    phoneNumberId: string,
    tenantId: string
  ) => Promise<ApolloQueryResult<ISingleDripCampaignType>>
  getCampaigns: (
    tenantId: string,
    phoneNumberId: string,
    status: string,
    nameContains: string,
    itemsPerPage: number,
    offset: number
  ) => Promise<Array<ISingleDripCampaignType>>
  messageCreate: (
    dripCampaign_id: string,
    message: DripCampaignMessageType
  ) => Promise<DripCampaignMessageCreate>
  updateCampaignState: (
  routeIds: RouteIds,
  campaign: ISingleDripCampaignType) => Promise<DripCampaignUpdate>
  messageUpdate: (campaignId: string, message: DripCampaignMessageType) => Promise<any>
  messageDelete: (campaignId: string, messageId: string) => Promise<any>
  suscriptionCreate: (subscriptions: Array<DripCampaignSubscription>, campaignId: string) => Promise<any>
  suscriptionUpdate: (campaignId: string, subscriptions: any, status: string) => Promise<any>
  suscriptionDelete: (campaignId: string, subscriptions: any, status: string) => Promise<any>
  getSubscriptions:(
  routeIds: RouteIds,
  pageParams: PageParams,
  searchParams: {text: string, status: string}
  ) => Promise<DripCampaignSubscriptionListType>
}
export default class CampaignGql implements ICampaignGql {
  $apollo: ApolloClient<any>

  constructor (context: ApolloContext) {
    this.$apollo = context.$apollo
  }

  getSubscriptions ({ campaignId, tenantId, phoneNumberId }: RouteIds, { page, itemsPerPage }: PageParams, { status, text }: {text: string, status: string}): Promise<DripCampaignSubscriptionListType> {
    return this.$apollo.query({
      query: getDripCampaignSubscriptions,
      variables: {
        searchText: text,
        campaignId,
        phoneNumberId,
        tenantId,
        limit: itemsPerPage,
        offset: (page - 1) * itemsPerPage,
        status: status?.toLowerCase(),
        text
      }
    }).then((result: any) => result?.data.dripCampaignSubscriptions)
  }

  create (
    activeDate: string | null,
    automaticResponse: string,
    data: null,
    name: string,
    phoneNumberId: string,
    tenantId: string,
    unsubscribeOnReply: boolean
  ): Promise<IDripCampaignCreate> {
    return this.$apollo.mutate({
      mutation: createDripCampaign,
      variables: {
        activeDate,
        automaticResponse,
        data,
        name,
        phoneNumberId,
        tenantId,
        unsubscribeOnReply
      }
    }).then(result => result.data.dripCampaignCreate)
  }

  updateCampaignState ({ campaignId }: RouteIds, { publishSettings, campaign }: any): Promise<any> {
    let { activeDate } = campaign
    const status = publishSettings.timeMode.status
    if (campaign.isCreating) {
      activeDate = publishSettings.timeMode.getDate(publishSettings.date, publishSettings.time)
    }
    return this.$apollo.mutate({
      mutation: updateDripCampaign,
      variables: {
        campaignId,
        status,
        activeDate
      }
    }).then(result => result.data)
  }

  update (
    { campaignId }: RouteIds, campaign: IDripCampaignCreate
  ): Promise<DripCampaignUpdate> {
    return this.$apollo.mutate({
      mutation: updateDripCampaign,
      variables: {
        ...campaign,
        campaignId
      }
    })
  }

  getById (
    id: string,
    phoneNumberId: string,
    tenantId: string
  ): Promise<ApolloQueryResult<ISingleDripCampaignType>> {
    return this.$apollo.query({
      query: getDripCampaignById,
      fetchPolicy: 'no-cache',
      variables: {
        id,
        phoneNumberId,
        tenantId
      }
    }).then((result: any) => {
      return {
        ...result,
        data: {
          ...result.data,
          dripCampaign: {
            ...result.data.dripCampaign,
            campaignVariables: result.data.dripCampaign.campaignVariables.map((variable: string) => ({
              key: variable,
              title: variable
            }))
          }
        }
      }
    })
  }

  getCampaigns (
    tenantId: string,
    phoneNumberId: string,
    status: string,
    nameContains: string,
    itemsPerPage: number,
    offset: number
  ): Promise<Array<ISingleDripCampaignType>> {
    return this.$apollo.query({
      query: getDripCampaigns,
      fetchPolicy: 'no-cache',
      variables: {
        tenantId,
        phoneNumberId,
        status,
        nameContains,
        itemsPerPage,
        offset
      }
    }).then((result: any) => result?.data.dripCampaigns)
  }

  messageCreate (dripCampaign_id: string, message: DripCampaignMessageType) {
    return this.$apollo.mutate({
      mutation: dripCampaignMessageCreate,
      variables: {
        dripCampaign_id,
        deliveryDeltaScalar: message.deliveryDeltaScalar,
        deliveryDeltaUnit: message.deliveryDeltaUnit,
        template: message.template,
        sequence: message.sequence,
        status: message.status?.toLowerCase()
      }
    }).then(result => result.data.dripCampaignMessageCreate)
  }

  messageUpdate (campaignId: string, message: DripCampaignMessageType) {
    return this.$apollo.mutate({
      mutation: dripCampaignMessageUpdate,
      variables: {
        messageId: message.id,
        campaignId,
        deliveryDeltaScalar: message.deliveryDeltaScalar,
        deliveryDeltaUnit: message.deliveryDeltaUnit,
        status: message.status?.toLowerCase(),
        template: message.template
      }
    }).then(result => result.data.dripCampaignMessageUpdate)
  }

  messageDelete (campaignId: string, messageId: string) {
    return this.$apollo.mutate({
      mutation: dripCampaignMessageDelete,
      variables: {
        messageId,
        campaignId
      }
    })
  }

  suscriptionCreate (subscriptions: Array<any>, campaignId: string, updatePreviousContacts = false) {
    const subsWithVariablesFixed = subscriptions.map(({ first_name, last_name, email, name, phone, ...otherVariables }: {first_name: string, last_name: string, email: string, name: string, phone: string}) =>
      ({ variables: otherVariables, email, name, phone, firstName: first_name, lastName: last_name }))
    return this.$apollo.mutate({
      mutation: dripCampaignSuscriptionCreate,
      variables: {
        campaignId,
        subscriptions: subsWithVariablesFixed,
        update: updatePreviousContacts
      }
    }).then((result) => {
      const { failedCount, errors, campaign } = result.data?.dripCampaignSubscriptionCreateBulk as { created: Array<IDripCampaignCreate>; failedCount: number; errors: Array<any>, campaign: any }
      const { warning } = useSnackbar()
      if (failedCount > 0) {
        warning(`Failed to add ${failedCount} contacts to the campaign.`)
        // return
      }
      const campaignSubs = campaign?.subscriptions?.map(({ contact: { firstName, lastName, ...contact }, variables, ...sub }: { contact: { firstName: string, lastName: string }, variables: any }) =>
        ({ ...sub, variables, first_name: firstName, last_name: lastName, ...contact }))
      return {
        errors,
        ...campaign,
        campaignVariables: campaign?.campaignVariables?.map((variable: string) => ({
          key: variable,
          title: variable
        })),
        subscriptions: campaignSubs
      }
    })
  }

  suscriptionUpdate (campaignId: string, subscriptions: any, status: string) {
    return this.$apollo.mutate({
      mutation: dripCampaignSuscriptionUpdate,
      variables: {
        subscriptions,
        campaignId,
        status
      }
    }).then(result => result.data)
  }

  suscriptionDelete (campaignId: string, subscriptions: any) {
    return this.$apollo.mutate({
      mutation: dripCampaignSuscriptionDelete,
      variables: {
        subscriptions,
        campaignId
      }
    }).then(result => result.data?.dripCampaignSubscriptionDeleteBulk)
  }
}
